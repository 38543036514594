import './style.scss';
import ornamentSvg from '../../img/ornament.svg';
import ProgressBar from '../ProgressBar';
import styled from 'styled-components';
import { useState } from 'react';

const InfoRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  margin-top: 0;
  padding: 0 16px;
  position: relative;

  @media screen and (max-width: 568px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    margin-top: 24px;
  }
`;

const SummaryInfoRow = styled(InfoRow)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const InfoRowHeading = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fdf6ef;
  font-family: 'Cinzel', serif;
  width: 100%;
  text-align: center;
  margin: 72px 0 32px;

  small {
    font-size: 14px;
    font-family: 'Cinzel', serif;
    line-height: 1;
    height: auto;
    display: flex;
    align-items: flex-end;
    padding-left: 8px;
    width: 0;
    opacity: 0.65;
  }
`;

const InfoRowSeparator = styled.div`
  display: flex;
  background: linear-gradient(90deg, rgba(253, 246, 239, 0) 0%, rgba(253, 246, 239, 1) 27%, rgba(253, 246, 239, 1) 78%, rgba(253, 246, 239, 0) 100%);
  align-self: stretch;
  flex-shrink: 0;
  height: 2px;
  width: 100%;
  position: relative;
  margin: 32px 0;
`;

const ShowMoreLink = styled.a`
  color: #fdf6ef;
  font-family: 'Cinzel', serif;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  margin-top: 24px;
  text-underline-offset: 3px;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    //text-decoration: none;
    opacity: 0.65;
  }
`;

const FeatureSubElem = ({ text, value }) => (
  <div className="feature__sub-elem">
    <div className="feature__text">{text}</div>
    <div className="feature__container">
      <div className="feature__value">{value}</div>
    </div>
  </div>
);
const EmissionInfo = () => {
  const [isVisible, setIsVisible] = useState(false);

  const setVisible = () => {
    setIsVisible(true);
  };

  return (
    <>
      {!isVisible && <ShowMoreLink onClick={setVisible}>Pozrieť viac</ShowMoreLink>}

      {isVisible && (
        <>
          <InfoRowSeparator />
          <InfoRowHeading style={{ margin: '24px 0 32px' }}>
            2. cieľ <small>(emisia)</small>
          </InfoRowHeading>
          <InfoRow style={{ marginTop: 0 }}>
            <FeatureSubElem text="prijate investície" value="150 000 €" />
            <FeatureSubElem text="počet predaných dlhopisov" value="30 / 50" />
            <FeatureSubElem text="cieľová suma" value="250 000 €" />
            <FeatureSubElem text="status" value="V procese" />
          </InfoRow>
          <InfoRowSeparator />
          <InfoRowHeading style={{ margin: '24px 0 32px' }}>
            1. cieľ<small>(emisia)</small>
          </InfoRowHeading>
          <InfoRow style={{ marginBottom: '24px' }}>
            <FeatureSubElem text="prijate investície" value="745 000 €" />
            <FeatureSubElem text="počet predaných dlhopisov" value="149 / 149" />
            <FeatureSubElem text="cieľová suma" value="745 000 €" />
            <FeatureSubElem text="status" value="Ukončené" />
          </InfoRow>
        </>
      )}
    </>
  );
};

const Info = () => {
  return (
    <>
      <div className="feature">
        <div className="feature__decor">
          <img src={ornamentSvg} alt="ornament" />
        </div>
        <div className="feature__border"></div>
        <div className="feature__elem">
          <div className="feature__sub-elem">
            <div className="feature__text">minimálna investícia</div>
            <div className="feature__container">
              <div className="feature__value">5000 €</div>
            </div>
          </div>
        </div>
        <div className="feature__line feature__line--desktop"></div>
        <div className="feature__line feature__line--short feature__line--mobile"></div>

        <div className="feature__elem">
          <div className="feature__sub-elem">
            <div className="feature__text">obdobie</div>
            <div className="feature__container">
              <div className="feature__value">5 rokov</div>
            </div>
          </div>
        </div>
        <div className="feature__line feature__line--short feature__line--desktop"></div>
        <div className="feature__separator"></div>

        <div className="feature__elem">
          <div className="feature__sub-elem">
            <div className="feature__text">ročný výnos</div>
            <div className="feature__container">
              <div className="feature__value">6% p.a.</div>
            </div>
          </div>
        </div>
        <div className="feature__line"></div>

        <div className="feature__elem">
          <div className="feature__sub-elem">
            <div className="feature__text">bonusová kabelka</div>
            <div className="feature__container">
              <div className="feature__value">1000 €</div>
            </div>
          </div>
        </div>
      </div>
      <ProgressBar progressFill={98} />
      <SummaryInfoRow style={{ marginTop: '48px' }}>
        <FeatureSubElem text="celkové prijate investície" value="980 000 €" />
        <FeatureSubElem text="celkový počet predaných dlhopisov" value="196 / 199" />
        <FeatureSubElem text="celková cieľová suma" value="995 000 €" />
      </SummaryInfoRow>
      <EmissionInfo />
    </>
  );
};

export default Info;
