import './style.scss';
import ornamentSvg from '../../img/ornament.svg';

const ColumnFeature = ({ value, yearlyValue, bonusText, emptyBonus, number }) => {
  return (
    <div className="column-feature">
      <div className="column-feature__circles">
        <span></span>
        <span></span>
      </div>
      <div className="column-feature__block">
        <div className="column-feature__block--title">
          <p className="column-feature__block__sum">Príklad {number}</p>
        </div>
        <div className="column-feature__block--investment">
          <p className="column-feature__block__title">Investície</p>
          <span className="column-feature__block__sum">{value} EUR</span>
          <div className="ornament">
            <img src={ornamentSvg} alt="ornament" />
          </div>
        </div>

        <div className="column-feature__block--interest">
          <p className="column-feature__block__title">Celkový výnos</p>
          <span className="column-feature__block__major-value">{yearlyValue} EUR</span>
        </div>

        <div className={`column-feature__block--bonus ${emptyBonus ? 'column-feature__block--empty' : ''}`}>
          <p className="column-feature__block__title">Bonus</p>
          <span className="column-feature__block__sum column-feature__block__sum--small">{bonusText}</span>
        </div>

        <div className="column-feature__block__button">
          <a href="#contactForm" className="column-feature-button">
            Chcem investovať
            <svg className="column-feature-button__icon" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
              <path className="column-feature-button__icon__path" d="M1.19244 1.2832L6.47342 6.56418L1.19244 11.8451"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ColumnFeature;
