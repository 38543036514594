import './style.scss';
import ornamentSvg from '../../img/ornament.svg';

const InfoSecond = () => {
  return (
    <div className="feature feature--second">
      <div className="feature__decor">
        <img src={ornamentSvg} alt="ornament" />
      </div>
      <div className="feature__border"></div>
      <div className="feature__mobile-border"></div>
      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">Výnos</div>
          <div className="feature__container">
            <div className="feature__value">6% P.A.</div>
          </div>
        </div>
      </div>
      <div className="feature__line feature__line--desktop"></div>
      <div className="feature__line feature__line--short feature__line--mobile"></div>

      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">Splatnosť</div>
          <div className="feature__container">
            <div className="feature__value">5 rokov</div>
          </div>
        </div>
      </div>
      <div className="feature__line feature__line--desktop"></div>

      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">Vyplácanie</div>
          <div className="feature__container">
            <div className="feature__value">Pol ročne</div>
          </div>
        </div>
      </div>
      <div className="feature__line feature__line--mobile"></div>

      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">menovitá hodnota dlhopisu</div>
          <div className="feature__container">
            <div className="feature__value">5 000€</div>
          </div>
        </div>
      </div>
      <div className="feature__line feature__line--desktop"></div>
      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">Pri investicií 15 000€ +</div>
          <div className="feature__container">
            <div className="feature__value">Bonusová kabelka</div>
          </div>
        </div>
      </div>
      <div className="feature__line"></div>

      <div className="feature__elem">
        <div className="feature__sub-elem">
          <div className="feature__text">minimálna investícia</div>
          <div className="feature__container">
            <div className="feature__value">5 000€</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSecond;
