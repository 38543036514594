import './App.scss';
import logoSvg from './img/logo.svg';
import handbag from './img/kabelka.png';
import dajanaImage from './img/banner-dajana.png';
import bannerGrafImage from './img/banner-graf.png';
import bannerGrafImageMobile from './img/banner-graf-mobile.png';
import grafImageMobile from './img/graf-mobile.png';
import timelineImage from './img/timeline.png';
import portraitsImage from './img/portraits.png';
import Info from './componens/Info/Info';
import ContactForm from './componens/ContactForm/ContactForm';
import TextBlock from './componens/TextBlock/TextBlock';
import Footer from './componens/Footer/Footer';
import { useMediaQuery } from 'react-responsive';
import ColumnFeature from './componens/ColumnFeature/ColumnFeature';
import Button from './componens/Button';
import InfoSecond from './componens/Info/InfoSecond';
import VideoSection from "./componens/VideoSection/VideoSection";

function App() {
  return (
    <>
      <div className="dlhopisy-page dlhopisy-page--first-grad">
        <div className="header">
          <img className="logo" src={logoSvg} alt="Logo" />
        </div>
        <div className="container small">
          <div className="hero-image">
            {useMediaQuery({ query: '(max-width: 991.98px)' }) && <img className="dlhopisy-hero" loading="lazy" src={bannerGrafImageMobile} alt="Hero" />}
            {useMediaQuery({ query: '(min-width: 992px)' }) && <img className="dlhopisy-hero" loading="lazy" src={bannerGrafImage} alt="Hero" />}
            <img className="hero-image__dajana" src={dajanaImage} alt="DajanaRodriguez" />
          </div>

          <Info />

          <VideoSection />
          <section className="column-features">
            <ColumnFeature value="5 000" yearlyValue="1 500" bonusText="-" emptyBonus={true} number={1} />
            <ColumnFeature value="10 000" yearlyValue="3 000" bonusText="-" emptyBonus={true} number={2} />
            <ColumnFeature value="15 000" yearlyValue="4 500" bonusText={[`Limitovaná kabelka`, <br />, `Karin trinity magnificent v hodnote 1000€`]} number={3} />
            <ColumnFeature value="20 000" yearlyValue="6 000" bonusText={[`Limitovaná kabelka`, <br />, `Karin trinity magnificent v hodnote 1000€`]} number={4} />
          </section>
          <section className="timeline">
            <div className="timeline__title">Náš príbeh</div>
            <div className="timeline__description">Toto je náš príbeh transformácie z mladej rodinnej firmy na stabilné podnikanie s dlhodobou históriou a nepretržitým rastom ziskov.</div>
            <div className="timeline__content">
              <div className="timeline__column">
                <div className="timeline-item">
                  <span className="timeline-item__caption">2019</span>
                  <div className="timeline-item__rectangle" />
                  <div className="timeline-item__ellipse"></div>
                  <div className="timeline-item__title">
                    Pevné základy
                    <div className="timeline-item__description">Akvizícia novej budovy v hodnote 450 000 €, ktorá je základným kameňom rastu a prosperity našej firmy.</div>
                  </div>
                </div>
                <div className="timeline-item">
                  <span className="timeline-item__caption">2020</span>
                  <div className="timeline-item__rectangle" />
                  <div className="timeline-item__ellipse"></div>
                  <div className="timeline-item__title">
                    Flexibilita
                    <div className="timeline-item__description">
                      Úspešnosť projektu výroby rúšok v covidovom období za 650 000 € pre Tatrabanku, Rakúsku poštu a Haribo nás presvedčila o tom, že dokážeme dosahovať zisk aj v ťažkých časoch, pričom sa náš tím krátkodobo rozrástol až na
                      150 zamestnancov.
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <span className="timeline-item__caption">2021</span>
                  <div className="timeline-item__rectangle" />
                  <div className="timeline-item__ellipse"></div>
                  <div className="timeline-item__title">
                    Retail
                    <div className="timeline-item__description">Otvorenie kamennej predajne v srdci Bratislavy na prestížnej Laurinskej ulici, významne posilnilo viditeľnosť našej značky v hlavnom meste.</div>
                  </div>
                </div>
                <div className="timeline-item">
                  <span className="timeline-item__caption">2022</span>
                  <div className="timeline-item__rectangle" />
                  <div className="timeline-item__ellipse"></div>
                  <div className="timeline-item__title">
                    Rast
                    <div className="timeline-item__description">
                      Vďaka vám (klientom) a našej iniciatíve „Daruj Srdce Ukrajine“ sme vyzbierali 70 000 € na podporu žien a detí poznačených vojnovým konfliktom. Úspešne sme zdvojnásobili výrobu a dostavbou sme rozšírili firemné
                      priestory o vyše 400m2.
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <span className="timeline-item__caption">2023</span>
                  <div className="timeline-item__rectangle" />
                  <div className="timeline-item__ellipse"></div>
                  <div className="timeline-item__title">
                    Rozširovanie
                    <div className="timeline-item__description">
                      Rozšírenie marketingového teamu, otvorenie ďalšej kamennej pobočky OC Nivy a takmer 40% medziročný rast nám jasne zadefinovali naše ciele a smerovanie do najbližších rokov.
                    </div>
                  </div>
                </div>
                <div className="timeline-item timeline-item--final">
                  <span className="timeline-item__caption timeline-item__caption--final">2024</span>
                  <div className="timeline-item__ellipse timeline-item__ellipse--final"></div>
                  <div className="timeline-item__title timeline-item__title--final">Expanzia do zahraničia</div>
                </div>
              </div>
              {useMediaQuery({ query: '(min-width: 992px)' }) && (
                <div className="gallery">
                  <img className="gallery__image" alt="Placeholder" src={timelineImage} />
                  {/*<div className="gallery__frame">*/}
                  {/*  <div className="gallery__frame--type1">*/}
                  {/*    */}
                  {/*    <img className="gallery__image gallery__image--type1" alt="Placeholder" src="https://placehold.co/500x500" />*/}
                  {/*  </div>*/}
                  {/*  <img className="gallery__image gallery__image--type2" alt="Placeholder" src="https://placehold.co/640x937" />*/}
                  {/*</div>*/}
                  {/*<div className="gallery__frame">*/}
                  {/*  <img className="gallery__image gallery__image--type2" alt="Placeholder" style={{ backgroundColor: 'white', objectFit: 'contain', padding: '0 42px' }} src="https://placehold.co/640x937" />*/}
                  {/*  <div className="gallery__frame--type2">*/}
                  {/*    <img className="gallery__image gallery__image--type3" alt="Placeholder" src="https://placehold.co/500x500" />*/}
                  {/*    <img className="gallery__image gallery__image--type3" alt="Placeholder" src="https://placehold.co/500x500" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              )}
            </div>
            <div className="timeline__bottom">
              <Button text={'Kontaktujte nás'} />
            </div>
          </section>
          <section className="graph-show">
            <div className="graph-show__title">Stabilita a rastúce tržby</div>
            <div className="graph-show__image">
              {useMediaQuery({ query: '(max-width: 767.98px)' }) && <img loading="lazy" src={grafImageMobile} className="graph-show__image" alt="Graph" />}
              {useMediaQuery({ query: '(min-width: 768px)' }) && <img loading="lazy" src={bannerGrafImage} className="graph-show__image" alt="Graph" />}
            </div>
          </section>
          <section>
            <InfoSecond />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '48px' }}>
              <Button text={'Kontaktujte nás'} bgColor={'#000'} />
            </div>
          </section>
        </div>
      </div>
      <div className="dlhopisy-page dlhopisy-page--no-padding">
        <div className="container small">
          <section className="photo-show">
            <div className="photo-show__image-column">
              <img loading="lazy" src={portraitsImage} className="photo-show__image" alt="Placeholder" />
            </div>

            <div className="photo-show__info-column">
              <div className="photo-show__content">
                <div className="photo-show__headline">Rodinná Firma s Víziou</div>
                <div className="photo-show__description">
                  Naša cesta začala ako rodinné podnikanie so silnou víziou vytvoriť prvú svetovú módnu značku zo Slovenska. Vďaka nášmu tímu sme dokázali čeliť každej výzve, ktorú nám trh priniesol, a premeniť ju na príležitosť pre rast a
                  zdokonalenie.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="dlhopisy-page dlhopisy-page--net">
        <div className="container small">
          <section className="purple-section">
            <div className="reward-texts">
              <h2 className="reward-texts__title">Unikátna kabelka, ktorá zarába</h2>
              <div className="reward-texts__description">
                <p>
                  Predstavujeme našu limitovanú kabelku <span>Karin Trinity Magnificent</span>,<br /> ktorú nenájdete v predaji. Tento štýlový produkt nie je len módnym doplnkom, ale aj investičnou odmenou.
                </p>

                <p>
                  Zakúpte aspoň tri dlhopisy od Dajany Rodriguez v celkovej sume <span>15 000 €</span>
                  <br /> a získajte túto limitovanú a nepredajnú kabelku v hodnote <span>1 000 €.</span>
                </p>

                <p>
                  <strong>Buďte súčasťou nášho príbehu a investujte s nami!</strong>
                </p>
              </div>
            </div>
            <img className="reward-video" src={handbag} alt="Handbag" />
          </section>
          <div id="contactForm">
            <ContactForm />
          </div>
        </div>
      </div>
      <div className="dlhopisy-page">
        <div className="container small">
          <section>
            <TextBlock />
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
