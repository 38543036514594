import './style.scss';

const Footer = () => {
  return (
    <footer className="footer-bar">
      <div className="footer-bar__info">2024 © Dajana Rodriguez</div>
    </footer>
  );
};

export default Footer;
