function Button({ bgColor, text }) {
  const styles = bgColor ? { backgroundColor: bgColor } : {};

  return (
    <a href="#contactForm" className="button" style={styles}>
      <span className="button__circles">
        <span></span>
        <span></span>
      </span>
      {text}
    </a>
  );
}

export default Button;
