import Button from "../Button";
import {useState} from "react";
import styled from "styled-components";

const ShowMoreLink = styled.a`
    color: #fdf6ef;
    font-family: 'Cinzel', serif;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: 24px;
    text-underline-offset: 3px;
    transition: opacity 0.15s ease-in-out;

    &:hover {
        //text-decoration: none;
        opacity: 0.65;
    }
`;

const VideoTitle = styled.h3`
    color: #fdf6ef;
    font-family: 'Cinzel', serif;
    text-align: center;
    width: 100%;
    margin: 0 0 42px;
    font-size: 36px;
`

const VideoSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    const setVisible = () => {
        setIsVisible(true);
    };

    return (
        <>
            <section className="video-section">
                {/*<VideoTitle>*/}
                {/*    Aktuálna kampaň*/}
                {/*</VideoTitle>*/}
                <div className="video-section__container">
                    <div className="video-section__half">
                        <div className="video-section__texts">
                            <h2 className="video-section__title">Predstavujeme jedinečnú príležitosť stať sa súčasťou
                                nášho
                                vzrušujúceho príbehu.</h2>
                            <div className="video-section__text">Nenechajte si ujsť vzácnu príležitosť investovať do
                                Dajana
                                Rodriguez, ktorá vám zaručí pravidelný výnos, a nám umožní expandovať na medzinárodné
                                trhy.
                            </div>
                            <Button text={'Kontaktujte nás'}/>
                        </div>
                    </div>
                    <div className="video-section__half">
                        <iframe
                            width="auto"
                            height="auto"
                            src="https://www.youtube.com/embed/NeyjNvWn1tA?si=5u94EcpHO-CE61jX"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </section>

            {!isVisible && <ShowMoreLink onClick={setVisible}>Pozrieť viac</ShowMoreLink>}

            {isVisible && (<>
                <section className="video-section">
                    <VideoTitle>
                        Začiatok investovania
                    </VideoTitle>
                    <div className="video-section__container">
                        <div className="video-section__half">
                            <iframe
                                width="auto"
                                height="auto"
                                src="https://www.youtube.com/embed/SjVcx5mYZaI?si=i3747BLHsymnC9Yj"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="video-section__half">
                            <div className="video-section__texts">
                                <h2 className="video-section__title">Predstavujeme jedinečnú príležitosť stať sa
                                    súčasťou
                                    nášho
                                    vzrušujúceho príbehu.</h2>
                                <div className="video-section__text">Nenechajte si ujsť vzácnu príležitosť investovať do
                                    Dajana
                                    Rodriguez, ktorá vám zaručí pravidelný výnos, a nám umožní expandovať na
                                    medzinárodné
                                    trhy.
                                </div>
                                <Button text={'Kontaktujte nás'}/>
                            </div>
                        </div>
                    </div>
                </section>
            </>)}
        </>
    )
}

export default VideoSection;