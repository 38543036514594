import styled, { keyframes } from 'styled-components';

const PercentageText = styled.span`
  position: absolute;
  width: 100%;
  text-align: center;
  color: #fdf6ef;
  font-family: 'Cinzel', serif;
  top: 50%;
  transform: translateY(-50%);
`;

const ProgressBarOuter = styled.div`
  font-size: 22px;
  color: #fdf6ef;
  text-decoration: none;
  border: 1px solid rgb(70 18 109);
  border-radius: 9px;
  position: relative;
  background-color: #19012b;
  transition: background-color 0.15s ease-in-out;
  font-family: 'Cinzel', serif;
  box-shadow: 0 0 6px 0 rgb(70 18 109);

  @media screen and (max-width: 992px) {
    max-width: 100%;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    max-width: 85%;
    margin: 0 auto;
  }
`;

// const ProgressMark = styled.div`
//   position: absolute;
//   top: calc(100% + 10px);
//   transform: translateX(-50%);
//   color: #fdf6ef;
//   font-family: 'Cinzel', serif;
//   font-size: 18px;
//   white-space: nowrap;
//   text-align: center;
//
//   @media screen and (max-width: 768px) {
//     font-size: 14px;
//   }
//
//   &::before {
//     content: '';
//     position: absolute;
//     bottom: calc(100% + ${props => (props.isLast ? '13px' : '10px')});
//     left: 50%;
//     transform: translateX(-50%);
//     width: 2px;
//     box-shadow: 0 0 6px 2px rgb(164 18 255);
//     height: ${props => (props.isLast ? '38px' : '42px')};
//     background-color: rgb(164 18 255);
//     z-index: 1;
//     border-bottom-right-radius: ${props => (props.isLast ? '9px' : '0')};
//     border-top-right-radius: ${props => (props.isLast ? '9px' : '0')};
//   }
// `;

const move = keyframes`
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 5000% 0;
    }
    100% {
        background-position: 8000% 0;
    }
`;

const ProgressBarInner = styled.div`
  height: 42px;
  background: linear-gradient(rgba(74, 19, 118, 0.65), transparent), repeating-linear-gradient(135deg, #45116f 0 0.5em, #2c054a 0 1em);
  -moz-border-radius-topleft: 9px;
  -moz-border-radius-bottomleft: 9px;
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  transition: width linear;
  animation: ${move} 300s linear infinite;
  background-size: 81% 100%;
`;

const ProgressBar = ({ progressFill = 10 }) => {
  return (
    <ProgressBarOuter>
      {/*<ProgressMark style={{left: '75%'}} position={markPosition}><>Cieľ <br/> 1. emisie</></ProgressMark>*/}
      {/*<ProgressMark style={{borderTopRightRadius: '9px', borderBottomRightRadius: '9px', left: 'calc(100% - 2px)', height: '38px', top: 'calc(100% + 11px)'}} isLast><>Cieľ <br/> 2. emisie</></ProgressMark>*/}
      <ProgressBarInner style={{ width: `${progressFill}%` }} />
      <PercentageText style={{ width: `100%` }}>{progressFill}%</PercentageText>
      {/*<PercentageText style={{ width: `25%`, left: 'unset', right: 0 }}>{percentageSecond}%</PercentageText>*/}
    </ProgressBarOuter>
  );
};

export default ProgressBar;
