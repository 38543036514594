import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';

import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import OptOutForm from './componens/OptOutForm/OptOutForm';
// import { DevSupport } from '@react-buddy/ide-toolbox';
// import { ComponentPreviews, useInitial } from './dev';

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<App />} />
      <Route path="/odhlasenie" element={<OptOutForm />} />
      <Route path="*" element={<App />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Toaster />
    {/*<DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>*/}
    <RouterProvider router={router} />
    {/*</DevSupport>*/}
  </React.StrictMode>
);
